@import "sass-lib";
@media (min-width: 0px) and (max-width: 1680px) {
	.wrapper-head{ width: 1170px;  }
	.wrapper{ width: 1170px; }
	.middle{
		.wrapper{
			width: 980px;
		}
		s.banner-std .qts{
			top: 10%;
		}
		.container{ width: 1280px; }
		.images-middle{
			margin: 0 -40px 20px;
		}
	}
	.home{
		h3{ line-height: 50px; }
		&.qoutes{
			padding: 35px 0;
			.small-tittle{ margin-bottom: 20px; }
			.txt{
				margin-top: 30px;
				i{ font-size: 20px; line-height: 25px; }
			}
		}
		&.contact{
			.bg{
				background-position: left; background-size: cover; width: 45%;
			}
		}
	}
	.text-wrap{
		.wrapper{ padding: 0 40px; }
	}
	.wrap-content{
		margin-top: -100px;
		&:before{
			
		}
	}

	footer .top .left .box{
		margin-right: 60px;
	}
}

@media only screen and (max-width:1480px){
    .under-con{
    	.logo{ left: 8%; }
        .wrapper{ padding-top: 220px; }
    }
}

@media (min-width: 0px) and (max-width: 1280px) {
	.wrapper{ width: 904px; }
	.wrapper-head{ width: 904px; }
	.wrap-content{
		&:before{ left:-55px; }
		&.no-margin{
			padding-top: 80px; 
			&:before{ display:none; }
		}
	}
	.btn{ line-height: 35px; font-size: 13px; letter-spacing: 1px; padding: 0 20px; }
	.btn-loadmore{ line-height: 35px; }
	.home{
		h3{ font-size: 42px; line-height: 45px; }
		.slider-home{
			h2{ font-size: 70px; }
			small{ margin-bottom: 20px; }
		}
		&.service{
			padding: 100px 0;
			.box-service{
				.list-service{ 
					padding: 0 20px; 
					.titt{
						padding-right: 35px;
						.t-s{
							font-size: 22px;
						}
						.bullet{ right: 0; }
					}
				}
			}

		}
		&.product{
			.box{
				margin: 0 -20px;
				.list-product{ padding: 0 20px; }
			}
		}
		&.news{
			padding: 120px 0; overflow: hidden;
			.box-news{
				.wrap-news{ 
					margin: 0 -15% 0 0; width: 80%;
					.list-news{ padding: 0 20px; }
				}
				.desc-new{ width: 30%; }
			}

		}
		&.contact{
			.wrap-contact{ padding-left: 0; }
			.bg{
				width: 35%;
			}
		}
	}
	.middle{
		.container{ width: 903px; }
		.wrapper{  width:860px; }
		.banner-std{
			.qts{ 
				top:15%;
				span{ font-size: 50px; }
			}
		}
	}
	.tittle-middle{
		small{ font-size: 13px; }
		&:before{ top:-30px; left: -40px; }
	}
	.tittle-section{
		margin-bottom: 40px;
	}
	.customer{margin-bottom: 50px;	}
	.partner{ margin-bottom: 50px; }
	.desc-sys{
		.box-desc{ padding-right: 40px; margin-top: 30px; }
	}
	.news-event {
		h2{ font-size: 35px; height: 140px; }
		.abslt{
			left: 70px; 
			p{ font-size: 14px; line-height: 18px; }
		}
	}
	.content-tab{
		.tab{
			ul{
				li{
					a{ font-size: 24px; }
				}
			}
		}
	}
	footer{
		.top{
			.left{
				width: 70%;
				.box{ margin-right: 40px; }
			}
		}
	}
}


@media only screen and (max-width:1279px){
    
    .under-con{
		.wrapper{ width:940px; }
        h1{ font-size: 40px; }
        h3{ font-size: 20px; }
        .wrapper{ 
            .text-content{ 
                width: 400px; 
                h1{ line-height: 50px; }
            }
            .form{
                input, textarea{ width: 400px; }
            }
        }
    }
}